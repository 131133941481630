<template>
  <!-- FOOTER SECTION -->
  <v-sheet elevation="5" class="footer py-7 py-lg-16">
    <div class="text-center text-dark-emphasis">
      <p class="footer-title my-0">Lifeline Educational Solutions</p>
      <small class="footer-copyright text-dark-50 d-block py-0"
        >&copy; {{ new Date().getFullYear() }} - Copyright: All rights
        reserved</small
      >

      <div class="footer-icons pb-sm-5">
        <v-icon
          v-for="(media, index) in socialMedia"
          :key="index"
          :href="media.link"
          target="_blank"
          class="mx-2"
          :icon="media.icon"
          :color="media.color"
          :size="media.size"
        ></v-icon>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      socialMedia: [
        {
          icon: "mdi-facebook",
          link: "https://web.facebook.com/Lifelineeducationalsolutions",
          size: "24",
          color: "grey-darken-2",
        },
        {
          icon: "mdi-twitter",
          link: "https://twitter.com/Lifelineeducat1",
          size: "24",
          color: "grey-darken-2",
        },
        {
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/in/lifeline-educational-solutions-1b0b3b1b4/",
          size: "24",
          color: "grey-darken-2",
        },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/lifelineeducationsolutions/",
          size: "24",
          color: "grey-darken-2",
        },
        {
          icon: "mdi-whatsapp",
          link: "https://wa.me/233243934353",
          size: "24",
          color: "grey-darken-2",
        },
      ],
    };
  },
};
</script>

<style scoped>
#footer {
  padding: 10%;
}
.footer-icons {
  color: red;
  /* background-color: #bb2525; */
  font-size: 2rem;
  margin: 0 7px;
  padding-bottom: 2%;
}

.footer-title {
  font-size: 3vw;
}

.footer-copyright {
  font-size: 1.5vw;
}

.laptop {
  position: relative;
}

/* Contact Email and Phone */
.phone {
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid white;
  border-radius: 10px;
  width: 60%;
  padding: 5% 3%;
  position: absolute;
  bottom: 20%;
  left: 20%;
}

.email {
  background-color: green;
  border-radius: 10px;
  width: 60%;
  padding: 5% 3%;
  position: absolute;
  bottom: 20%;
  left: 20%;
}

.mail,
.tel {
  font-size: 1.7vw;
  font-family: Georgia, "Times New Roman", Times, serif;
}
</style>
